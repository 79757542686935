import type { FetchOptions } from '~/interfaces/types/common'
import { useLocale } from '~/locale/util'

export function useClientFetch<R = any>() {
  const { locale } = useLocale()

  return async (path: string, opts?: FetchOptions): Promise<any> => {
    opts = opts ?? {}
    if (locale) {
      opts.headers = {
        ...(opts.headers ?? {}),
        'Accept-Language': `${locale.value}`
      }
    }

    return await $fetch<R>(path, opts)
  }
}
